import React from "react";
import {
  isIOS,
  isAndroid
} from "react-device-detect";
import currencyFormatter from "currency-formatter";
import moment from "moment";
import _ from "lodash";
import { ApiUrl } from "./Constants";
// import { JSEncrypt } from "jsencrypt";
// import { RSAPublicKey, QB_APPID, QB_AUTHKEY, QB_AUTHSECRET } from '../commons/Constants';

// export const encryptRequest = (password) => {
//     var encrypt = new JSEncrypt();
//     encrypt.setPublicKey(RSAPublicKey);
//     return encrypt.encrypt(password);
// }
//获取sessionStorage存储数据
export const getCache = (name) => {
  if (!name) return;
  return window.sessionStorage.getItem(name);
};
//设置sessionStorage存储数据
export const setCache = (name, content) => {
  if (!name) return;
  if (typeof content !== "string") {
    content = JSON.stringify(content);
  }
  window.sessionStorage.setItem(name, content);
};
//删除sessionStorage存储数据
export const removeCache = (name) => {
  if (!name) return;
  window.sessionStorage.removeItem(name);
};
//获取localStorage存储数据
export const get_local_cache = (name) => {
  if (!name) return;
  return window.localStorage.getItem(name);
};
//设置localStorage存储数据
export const set_local_cache = (name, content) => {
  if (!name) return;
  if (typeof content !== "string") {
    content = JSON.stringify(content);
  }
  window.localStorage.setItem(name, content);
};
//删除localStorage存储数据
export const remove_local_cache = (name) => {
  if (!name) return;
  window.localStorage.removeItem(name);
};

export const urlEncode = (param, key, encode) => {
  if (param == null) return "";
  var paramStr = "";
  var t = typeof param;
  if (t === "string" || t === "number" || t === "boolean") {
    paramStr +=
      "&" +
      key +
      "=" +
      (encode == null || encode ? encodeURIComponent(param) : param);
  } else {
    for (var i in param) {
      var k =
        key == null
          ? i
          : key + (param instanceof Array ? "[" + i + "]" : "." + i);
      paramStr += urlEncode(param[i], k, encode);
    }
  }
  return paramStr;
};

export const isArray = (obj) => {
  return typeof obj == "object" && obj.constructor == Array;
};

export const mobileBigFormate = (obj) => {
  if (!obj) {
    return "";
  }
  return (
    obj.substring(0, 1) + "***" + obj.substring(obj.length - 4, obj.length)
  );
};

export const emailBigFormate = (obj) => {
  if (!obj) {
    return "";
  }
  return obj.substring(0, 2) + "***" + obj.substr(obj.indexOf("@"));
};

const colorStyle = {
  display: "inline-block",
  verticalAlign: "middle",
  width: "18px",
  height: "18px",
  marginRight: "10px",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
};

export const addKeyValueForDorpList = (data, key = "") => {
  let res = [];
  if (!data && data.length === 0) return;

  if (key) {
    res = data.map((v, i) => {
      if (key === "dialing_code") {
        return {
          label: (
            <span key={v.id}>
              <span
                style={{
                  ...colorStyle,
                  backgroundImage:
                    "url(" + v["flag_image_url"]["url"]["s"] + ")",
                }}
              />
              <span>{`+ ${v[key]}`}</span>
            </span>
          ),
          value: v[key],
        };
      } else if (key === "dialing_code_name") {
        return {
          label: (
            <span key={v.id}>
              <span
                style={{
                  ...colorStyle,
                  backgroundImage:
                    "url(" + v["flag_image_url"]["url"]["s"] + ")",
                }}
              />
              <span>{v["name"]}</span>
              <span>{` + ${v["dialing_code"]}`}</span>
            </span>
          ),
          value: v["dialing_code"],
        };
      } else if (key === "country_list") {
        return {
          label: v["name"],
          value: v["code"],
          postal_code_format: v["postal_code_format"],
        };
      } else {
        return {
          label: v[key],
          value: v[key],
        };
      }
    });
  } else {
    res = data.map((v, i) => {
      return {
        label: v,
        value: v,
      };
    });
  }

  return res;
};

export const isEmail = (val) => {
  let re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(val).toLowerCase());
};

export const justEmail = (obj) => {
  let re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(obj.val()).toLowerCase());
};

export const isMobileNo = (val, dialingCode = "65", reg) => {
  if (reg) {
    return eval(reg).test(String(val).toLowerCase());
  } else if (dialingCode == "86") {
    reg = /^1[3456789]\d{9}$/;
  } else if (dialingCode == "65") {
    reg = /^(8|9)\d{7}$/;
  } else {
    reg = /^[0-9]*$/;
  }

  return reg.test(String(val).toLowerCase());
};

export const justMobileNo = (obj) => {
  let re = /^(8|9)\d{7}$/;
  return re.test(obj.val());
};

export const isPostalCode = (obj, type = "SG", reg) => {
  if (reg) {
    return eval(reg).test(String(obj));
  } else if (type === "SG") {
    var reg = /^[0-9]{6}$/;
  } else {
    var reg = /^[0-9a-zA-Z]+$/;
  }
  return reg.test(String(obj));
};

export const justPostalCode = (obj) => {
  var reg = /^[0-9]{6}$/;
  return reg.test(obj.val());
};

// 纯数字验证
export const vCodeChange = (e) => {
  var value = e;
  var reg = /^\d*?$/; // 以任意数字开头和结尾，且中间出现零个或多个数字
  if (reg.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const isIDNo = (obj, reg) => {
  if (reg) {
    return eval(reg).test(String(obj));
  } else {
    var reg = /^[0-9a-zA-Z]+$/;
  }
  return reg.test(String(obj));
};

export const isSame = (d1, d2, type = "day") => {
  return moment(d1).isSame(d2, type);
};

export const showDate = (date, type = "") => {
  if (type === 13) {
    // 22 Dec 2021 14:30
    return moment(date).format("DD MMM YYYY HH:mm");
  } else if (type === 12) {
    return moment(date).valueOf();
  } else if (type === 11) {
    //2021-04-15 17:24:12
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  } else if (type === 10) {
    return moment(date).toDate();
  } else if (type === 9) {
    return moment(date).format("YYYY/MM/DD");
  } else if (type === 8) {
    return moment(date).format("DD MMM YYYY");
  } else if (type === 7) {
    return moment(date).format("DD MMM H:mm A");
  } else if (type === 6) {
    return moment(date).format("H:mm a");
  } else if (type === 5) {
    return moment(date).format("DD MMM");
  } else if (type === 4) {
    return moment(date).format("dddd");
  } else if (type === 3) {
    return moment(date).format("YYYY-MM-DD H:mm");
  } else if (type === 2) {
    return moment(date).format("YYYY-MM-DD");
  } else if (type === 1) {
    return moment(date).format("H:mm");
  } else {
    return moment(date).format("DD/MM/YYYY");
  }
};

//英文标题按单词截取(参数说明 text:要截取的英文 len：要截取的长度)
export const sliceEnglish = (text, len) => {
  let _text = text.replace(/[\r\n]/g, "");

  if (!_text) {
    return "";
  }

  //如果要截取文本的长度小于或者等于要截取的长度，则不进行截取，直接返回文本
  if (_text.length < len || len === "" || len === 0) {
    return _text;
  }
  //文本的长度大于要截取的长度，进行截取
  else {
    _text = _text.substr(0, len);
    //       以空格切分字符串
    var textArr = _text.split(" ");
    //           最后一个字符长度
    var lastLen = textArr.pop().length;
    if (lastLen > 3) {
      return _text.substr(0, _text.length - lastLen - 1) + " ...";
    } else if (lastLen === 3) {
      return _text;
    } else {
      var lastTwoLen = textArr[textArr.length - 1].length;

      return _text.substr(0, _text.length - lastLen - lastTwoLen - 2) + " ...";
    }
  }
};
export const currencyType = (_value, _code) => {
  return currencyFormatter.format(_value, { code: _code });
};

export const getWeek = () => {
  let week = moment().day();
  switch (week) {
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    case 0:
      return "Sunday";
  }
};

// 获取星期简称
export const getSWeek = (data) => {
  let week = moment(data).day();
  switch (week) {
    case 1:
      return "M";
    case 2:
      return "T";
    case 3:
      return "W";
    case 4:
      return "T";
    case 5:
      return "F";
    case 6:
      return "S";
    case 0:
      return "S";
  }
};

export const getSlotTimes = (timeslots, duration, lead_time) => {
  let res = [];
  timeslots =
    timeslots.length > 0 || timeslots instanceof Array
      ? timeslots
      : JSON.parse(timeslots);
  timeslots.map((item, index) => {
    let obj = {};
    let slorts = _explodeTimeSlot(item, duration, lead_time);
    obj.slorts = slorts;
    obj.weekday = item.day;
    res.push(obj);
  });
  return res;
};

export const _explodeTimeSlot = (timeslot, duration, lead_time) => {
  let slots = [];
  timeslot.data.map(
    (i) =>
      (slots = slots.concat(_splitTime(i.start, i.end, duration, lead_time)))
  );
  return slots;
};

export const _splitTime = (startTime, endTime, duration, lead_time) => {
  let addMins = duration * 60;
  let returnArray = [];
  startTime =
    moment().format("YYYY-MM-DD") +
    " " +
    ([...startTime].length === 4 ? "0" + startTime : startTime);
  endTime =
    moment().format("YYYY-MM-DD") +
    " " +
    ([...endTime].length === 4 ? "0" + endTime : endTime);

  startTime = moment(startTime).valueOf() / 1000;
  endTime = moment(endTime).valueOf() / 1000;

  while (startTime <= endTime) {
    let arr = {};
    arr.slot = moment(startTime * 1000).format("H:mm");
    arr.duration = duration;
    arr.lead_time = lead_time * 86400000;
    startTime += addMins;
    returnArray.push(arr);
  }
  if (returnArray.length) {
    returnArray.pop();
  }
  return returnArray;
};

export const isInvalid = (val) => {
  if (typeof val === "undefined" || val === undefined || val === null) {
    return true;
  } else {
    return false;
  }
};

export const MoneyToFixed = (obj) => {
  if (obj !== "") {
    return parseFloat(obj).toFixed(2);
  }
};
export const showGender = (gender_code) => {
  if (
    gender_code === "f" ||
    gender_code === "F" ||
    gender_code === "female" ||
    gender_code === "Female"
  ) {
    return "female";
  }

  if (
    gender_code === "m" ||
    gender_code === "M" ||
    gender_code === "male" ||
    gender_code === "Male"
  ) {
    return "male";
  }

  return "";
};

export const showStatus = (status_code) => {
  if (status_code === "completed") {
    return "Paid";
  }

  if (status_code === "cancel") {
    return "Cancelled";
  }

  if (status_code === "processing") {
    return "Processing";
  }

  if (status_code === "confirmed") {
    return "Pending Payment";
  }

  if (status_code === "pending") {
    return "Pending";
  }

  if (status_code === "paying") {
    return "Paying";
  }

  if (status_code === "pick_up") {
    return "Pick Up";
  }

  if (status_code === "on_route") {
    return "On Route";
  }

  if (status_code === "NOT_REQUIRED") {
    return "NOT REQUIRED";
  }

  return status_code.toUpperCase();
};

export const showGlobalDate = (date) => {
  let chinaDate = date.toDateString(); //"Tue, 01 Jan 2019 16:00:00 GMT"
  let globalDate = date.toUTCString(); //"Wed Jan 02 2019"
  let chinaDateArray = chinaDate.split(" "); //["Wed", "Jan", "02", "2019"]
  let displayDate = `${chinaDateArray[2]} ${chinaDateArray[1]} ${chinaDateArray[3]}`;

  return displayDate;
};

// 数组是否有交集
export const isIntersect = (arr1, arr2) => {
    let start = [Math.min(...arr1), Math.min(...arr2)];
    let end = [Math.max(...arr1), Math.max(...arr2)];
    return Math.max(...start) < Math.min(...end);
}

// export const getGST = (total, per) => {
//     let price = (total * per) / (100 + per)
//     return price
// }
export const getGST = (subtotal = 0, gst_rate = 7, gstCalculation = 1) => {
  return gstCalculation === "1"
    ? _.round(
      parseFloat(subtotal) -
      (parseFloat(subtotal) * 100) / (100 + parseFloat(gst_rate)),
      2
    )
    : _.round((parseFloat(subtotal) * parseFloat(gst_rate)) / 100, 2);
};

export const toHump = (name) => {
  let str = "";
  if (name) {
    str = name.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
  }
  return str;
};

export const specialStrDecode = (data) => {
  if (!data) {
    return data;
  }
  let str = JSON.stringify(data);
  str = str.replace(/&lt;/g, "<");
  str = str.replace(/&gt;/g, ">");
  str = str.replace(/&#40;/g, "(");
  str = str.replace(/&#41;/g, ")");
  return JSON.parse(str);
};

export const copyClipboard = (url) => {
  var input = document.createElement("input");
  input.setAttribute("id", "copyInput");
  input.setAttribute("value", url);
  document.getElementsByTagName("body")[0].appendChild(input);
  document.getElementById("copyInput").select();
  if (document.execCommand("copy")) {
    console.log("复制成功");
  }
  document.getElementById("copyInput").remove();
};
// 正数,小数点后做多dots位
export const getValdots = (params, dots = 1) => {
  params = params.replace(/[^\d.]/g, "");
  params = params.replace(/^\./g, "");
  params = params.replace(/\.{2,}/g, ".");
  params = params.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
  let str = "^(\\-)*(\\d+)\\.(";
  for (let index = 0; index < dots; index++) {
    str += "\\d";
  }
  str += ").*$";
  params = params.replace(new RegExp(str), "$1$2.$3");
  return params;
};

// export const verifyNRIC = (nric) => {
//     // new js func ======>
//     if (
//         nric.length !== 9 ||
//         !['S', 'T', 'G', 'F'].includes(nric[0].toUpperCase())
//     ) {
//         return false;
//     }
//     var lastLetter = nric[8].toUpperCase();
//     var nricCheckDigits = 'JZIHGFEDCBA';
//     var finCheckDigits = 'XWUTRQPNMLK';
//     var digits = nric.slice(1, 8);
//     var weights = [2, 7, 6, 5, 4, 3, 2];
//     var sum = 0;

//     if (isNaN(digits)) {
//         return false;
//     }
//     if (['T', 'G'].includes(nric[0].toUpperCase())) {
//         sum += 4;
//     }
//     for (var i = 0; i < digits.length; i++) {
//         sum += parseInt(digits.charAt(i), 10) * weights[i];
//     }
//     if (['S', 'T'].includes(nric[0].toUpperCase())) {
//         return nricCheckDigits.charAt(sum % 11) === lastLetter;
//     }
//     return finCheckDigits.charAt(sum % 11) === lastLetter;
// }

export const verifyNRIC = (str, t) => {
  if (str.length != 9) {
    return false;
  }
  let checkSumNRIC = "JZIHGFEDCBA";
  let checkSumFIN = "XWUTRQPNMLK";
  let nricRE = /^[ST][0-9]{7}[JZIHGFEDCBA]$/;
  let finRE = /^[FGM][0-9]{7}[XWUTRQPNMLK]$/;
  if (t === "nric") {
    if (!nricRE.test(str)) {
      return false;
    }
    return checkSumCalculation(str, checkSumNRIC);
  } else if (t === "fin") {
    if (!finRE.test(str)) {
      return false;
    }
    return checkSumCalculation(str, checkSumFIN);
  } else {
    if (!nricRE.test(str) && !finRE.test(str)) {
      return false;
    }
    let checkSumN = checkSumCalculation(str, checkSumNRIC);
    let checkSumF = checkSumCalculation(str, checkSumFIN);
    return checkSumN || checkSumF;
  }
};

function checkSumCalculation(nric, checksum) {
  let total =
    nric[1] * 2 +
    nric[2] * 7 +
    nric[3] * 6 +
    nric[4] * 5 +
    nric[5] * 4 +
    nric[6] * 3 +
    nric[7] * 2;

  if (nric[0] == "T" || nric[0] == "G") {
    // shift 4 places for after year 2000
    total = total + 4;
  }
  if (nric[0] == "M") {
    // shift 4 places for after year 2000
    total = total + 3;
  }

  if (nric[8] === checksum[total % 11]) {
    return true;
  } else {
    return false;
  }
}

export const closeWindow = () => {
  if (navigator.userAgent.indexOf("MSIE") > 0) {
    // close IE
    if (navigator.userAgent.indexOf("MSIE 6.0") > 0) {
      window.opener = null;
      window.close();
    } else {
      window.open("", "_top");
      window.top.close();
    }
  } else {
    // close chrome;It is effective when it is only one.
    window.opener = null;
    window.open("", "_self");
    window.close();
  }
  setTimeout(() => {
    document.body.style = "display:none";
  }, 300);
};

/*
 * @Author: Zhengzq
 * @Date: 2022-05-16 17:50:25
 */
export const viewFile = (data, type) => {
  /*
      data:{url:'',title:'',type:''}
        - url 文件地址
        - title App内部title标题
        - type 文件类型
      type:
        - native_download_pdf 下载pdf后分享（已有）
        - native_share_url  直接分享url链接
        - native_open_web 新app webview页内打开链接
        - native_open_broswer 浏览器中打开链接
    */

  let isInIOS =  navigator.userAgent.indexOf("1docIos") !== -1
  let isInAndroid =  navigator.userAgent.indexOf("1docAndroid") !== -1

  let sendData = {
    ...data,
    type: "",
  };
  if (isInIOS || isInAndroid) {
    if (type === "native_download_pdf" && data?.url) {
      let fileType = getFileType(data.url);
      sendData.file_url = data.url;
      sendData.type = fileType;
      sendData.file_name =
        (data.file_name && `${data.file_name}.${fileType}`) ||
        `1doc download ${new Date().getTime()}`;
      if (isInIOS) {
        sendData = JSON.stringify(sendData);
      }
    } else if (data?.url) {
      sendData.type = getFileType(data.url);
    }

    if (isInIOS && type) {
      window.webkit.messageHandlers[type].postMessage(sendData);
    } else if (isInAndroid && type) {
      window.JsToAndroid[type](JSON.stringify(sendData));
    }
  } else {
    window.open(sendData.url);
  }
};

function getFileType(url) {
  let startIndex = url.lastIndexOf(".");
  if (startIndex !== -1) {
    return url.substring(startIndex + 1, url.length).toLowerCase();
  }
}


export const json2Form = (json) => {
  if (json instanceof FormData) {
    return json;
  }
  const formData = new FormData();
  Object.keys(json).forEach((key) => formData.append(key, json[key]));
  return formData;
};

const checkIsBase64File = (value) => {
  return (
    typeof value === "string" &&
    value.startsWith("data:") &&
    /^data:.+;base64,.+/i.test(value)
  );
};

export const rewriteDataConfigForCFWAF = (endpoint, data) => {
  let isFileIncluded = false;

  // Check if data contains any file
  if (data instanceof FormData) {
    isFileIncluded = Array.from(data.values()).some(
      (value) => value instanceof File || value instanceof Blob,
    );
  } else if (data && typeof data === "object") {
    isFileIncluded = Object.values(data).some(
      (value) => value instanceof File || value instanceof Blob,
    );
  }

  if (!isFileIncluded) {
    return {
      re_data: data,
      re_url: endpoint,
    };
  }

  let index = endpoint.indexOf("/");
  let uri_dir = endpoint.substring(0, index);
  let uri = endpoint.substring(index + 1);
  if (data instanceof FormData) {
    let base_64_file = Array.from(data.values()).some((value) =>
      checkIsBase64File(value),
    );
    let newFormData = new FormData();
    for (let key of data.keys()) {
      newFormData.append(key, data.get(key));
    }
    newFormData.append("base_64_file", base_64_file);
    newFormData.append("uri", uri);
    newFormData.append("uri_dir", uri_dir);
    data = newFormData;
  } else if (data && typeof data === "object") {
    data.base_64_file = Object.values(data).some((value) =>
      checkIsBase64File(value),
    );
    data.uri = uri;
    data.uri_dir = uri_dir;
  }

  return {
    re_data: json2Form(data || {}),
    re_url: ApiUrl.uploadFllesApi,
  };
};

